<template>
    <div class="page">
        <div class="header">
            <div class="div_top header_top flex">
                <div class="right flex">
                    <!-- <span class="quick">本周</span> -->
                    <!-- <span>本月</span>
					<span>本年</span> -->
                    <a-select v-model="allValue" @change="timeChange(allValue, 1)">
                        <a-select-option  v-for="(item, index) in timeArr" :key="index" :value="item.value">{{ item.label }}</a-select-option>
                    </a-select>
                    <a-date-picker class="head_date" v-model="timeStart" @change="onChange(1)"/>至
                    <a-date-picker class="head_date" v-model="timeEnd" @change="onChange(1)"/>
                </div>
            </div>
            <div class="bottom header_bottom">
                <div class="client flex">
                    <div class="flex title_small">
                        <img class="" src="@/assets/imgs/wap/client.png" alt=""/>
                        <span class="">客户数量</span>
                    </div>

                    <div class="client_con flex marf">
                        <div class="flex">
                            <span>全部客户<br /><span class="txt yellow" v-if="corp">{{$utils.numberToWan(corp.all)}}</span></span>
                        </div>
                        <div class="flex">
                            <span>新增客户<br /><span class="txt orange" v-if="corp">{{$utils.numberToWan(corp.new)}}</span></span>
                        </div>
                        <div class="flex">
                            <span>跟进客户<br /><span class="txt blue" v-if="corp">{{$utils.numberToWan(corp.follow_log)}}</span></span>
                        </div>
                    </div>
                </div>

                <div class="client flex" style="flex-direction: unset">
                    <div class="order">
                        <div class="flex title_small">
                            <img class="" src="@/assets/imgs/wap/order.png" alt=""/>
                            <span class="">合同数</span>
                        </div>
                        <div class="flex marf">
                            <span>已成交合同 <br /><span class="txt yellow">{{$utils.numberToWan(order.num)}}</span></span>
                        </div>
                    </div>
                    <div class="order">
                        <div class="flex title_small">
                            <img class="" src="@/assets/imgs/wap/money.png" alt=""/>
                            <span class="">成交金额</span>
                        </div>
                        <div class="flex marf">
                            <span>已成交合同金额 <br /><span class="txt orange">{{$utils.numberToWan(order.amount)}}</span></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
		
        <div class="div_con ranking">
            <div class="bottom ranking_bottom">
                <div class="flex title_small">
                    <img class="" src="@/assets/imgs/wap/rank.png" alt="" />
                    <span class="">任务</span>
                </div>
                <a-list :data-source="task.slice(0,3)">
                    <a-list-item class="taskList" slot="renderItem" slot-scope="item" @click="toList">
						<div class="task_content">{{item.task_content}}</div>
						<div class="flex task_down">
                       <div class="task_up">
							<span class="task_title">{{item.type}}</span>
						</div>
							<span>{{item.start}}~{{item.end}}</span>
							<span>处理人：{{item.creater_uid}}</span>
						</div>
                    </a-list-item>
                </a-list>
            </div>
        </div>
        <!-- <div class="div_con dynamic">
            <div class="div_top flex">
                <div class="flex title_small">
                    <img class="" src="@/assets/imgs/wap/dynamic.png" alt="" />
                    <span class="">平台动态</span>
                </div>
            </div>
            <div class="bottom dynamic_bottom">
                <div
                    class="flex dynamic_content"
                    v-for="(item, index) in news"
                    :key="index"
                >
                    <div class="dynamic_title">
                        <span class="red" v-if="index == 0">{{
                            index + 1
                        }}</span>
                        <span class="yellow" v-if="index == 1">{{
                            index + 1
                        }}</span>
                        <span class="blue" v-if="index == 2">{{
                            index + 1
                        }}</span>
                        <span class="con txt_over">{{ item.title }}</span>
                    </div>
                    <span>{{ item.created }}</span>
                </div>
            </div>
        </div> -->
        <!-- <div style="height:60px;"></div> -->
		<div class="div_con ranking">
            <div class="div_top flex">
                <div class="right flex">
                    <a-select v-model="rankValue" @change="timeChange(rankValue, 2)">
                        <a-select-option v-for="(item, index) in timeArr" :key="index" :value="item.value">{{ item.label }}</a-select-option>
                    </a-select>
                    <a-date-picker v-model="timeStart2" @change="onChange(2)" class="head_date"/>至<a-date-picker class="head_date" v-model="timeEnd2" @change="onChange(2)" />
                </div>
            </div>
            <div class="bottom ranking_bottom">
                <div class="flex title_small">
                    <img class="" src="@/assets/imgs/wap/rank.png" alt="" />
                    <span class="">销售排行榜</span>
                </div>
                <!-- <div class="ranking_up">
                    <span>新增客户数</span>
                    <span>排名从低到高</span>
                </div> -->
                <a-list :data-source="rank.order">
                    <a-list-item slot="renderItem" slot-scope="item, index">
                        <div class="flex ranking_content">
                            <span v-if="index < 3">
                                <img v-if="index == 0" src="@/assets/imgs/one.png" alt=""/>
                                <img v-else-if="index == 1" src="@/assets/imgs/two.png" alt=""/>
                                <img v-else-if="index == 2" src="@/assets/imgs/three.png" alt=""/>
                            </span>
                            <span class="ranking_num" v-else>{{index + 1}}</span>
                            <img :src="item.avatar" style="width: 30px;height: 30px;">
                            <span>{{ item.staff }}</span>
                            <div style="width: 120px;"><span :class="[index < 3 ? 'yellow' : '']">{{$utils.numberToWan(item.amount)}}元</span></div>
                        </div>
                    </a-list-item>
                </a-list>
            </div>
			<div style="height:100px"></div>
        </div>
    </div>
</template>
 
<script>
import { requestXml, getDateTime } from "../../assets/js/request";
var moment = require("moment");
export default {
    data() {
        return {
            //时间限制
            timeStart: null,
            timeEnd:null,
            timeStart2: null,
            timeEnd2:null,
            windowHeight: "",
            allValue: 3,
            rankValue: 3,
            corp: {},
            order: {},
            // 待办任务
            task: [],
            taskType: ["打电话","发短信","发微信","发邮件","分享文章","分享表单","分享文件","分享商品","客户拜访","其他",
            ],
            taskStatus: ["取消", "新建", "跟进中", "完成"],
            // 排行榜
            start: [],
            end: [],
            rank: [],
            value: 3,
            timeArr: [
                { label: "今日", value: 1 },
                { label: "本周", value: 2 },
                { label: "本月", value: 3 },
                { label: "全年", value: 4 },
            ],
            // 平台动态
            news: [],
        };
    },
    mounted: function () {
        this.windowHeight = document.body.clientHeight - 430 + "px";
        this.getTask();
        // this.getNews();
        this.start = moment().startOf("month").format("x");
        this.end = moment().endOf("month").format("x");
        this.getRank();
        this.getHome();
    },
    methods: {
        toList(){
            this.$router.push({
                path: "/scrm_wap/task/taskList",
            });
        },
        // disabledDate(current) {
           
        //     let str = "";
        //     if (this.allValue == 1) {
        //         str = "day";
        //     } else if (this.allValue == 2) {
        //         str = "week";
        //     } else if (this.allValue == 3) {
        //         str = "month";
        //     } else if (this.allValue == 4) {
        //         str = "year";
        //     }
        //     return current && current < this.timeStart.endOf(str);
        // },
        // disabledDate2(current) {
		// 	let str = '';
		// 	if(this.rankValue == 1){
		// 		str = 'day'
		// 	}else if(this.rankValue == 2){
				
		// 		str = 'week'
		// 	}else if(this.rankValue == 3){
				
		// 		str = 'month'
		// 	}else if(this.rankValue == 4){
				
		// 		str = 'year'
		// 	}
        //     return current && current < this.timeStart2.endOf(str);
		// },

        //更多 页面跳转
        goWeb(path) {
            this.$router.push({
                path: "/scrm_pc/" + path,
            });
        },
        // 获取首页数据
        getHome() {
            requestXml("/scrm/Statistics/getHomeDate","POST",(res) => {
                this.corp = res.corp;
                this.order = res.order;
            },{ start: this.start, end: this.end });
        },
        // 获取待办任务
        getTask() {
            requestXml("/scrm/Task/getMyList","POST",(res) => {
                for (let i = 0; i < res.list.length; i++) {
                    res.list[i].start = getDateTime(res.list[i].start);
                    res.list[i].end = getDateTime(res.list[i].end);

                    res.list[i].status = this.taskStatus[
                        Number(res.list[i].status)
                    ];
                    res.list[i].type = this.taskType[
                        Number(res.list[i].type - 1)
                    ];
                }

                this.task = res.list;
            },{ form: "myfollowed" });
        },
        // 设置开始时间0:0:0 结束时间23:59:59
        setTime(time, type) {
            if (type == 1) {
                time.setHours(0);
                time.setMinutes(0);
                time.setSeconds(0);
            } else {
                time.setHours(23);
                time.setMinutes(59);
                time.setSeconds(59);
            }
            return time.getTime();
        },
        // 本周 本月 全年
        timeChange(val, type) {
            if (val == 1) {
                this.start = moment().startOf("day").format("x");
                this.end = moment().endOf("day").format("x");
            } else if (val == 2) {
                this.start = moment().startOf("week").add(1, "day").format("x");
                this.end = moment().endOf("week").add(1, "day").format("x");
            } else if (val == 3) {
                this.start = moment().startOf("month").format("x");
                this.end = moment().endOf("month").format("x");
            } else {
                this.start = moment().startOf("year").format("x");
                this.end = moment().endOf("year").format("x");
            }
            if (type == 2) {
                this.timeStart2='';
                this.timeEnd2='';
                this.getRank();
            } else {
                this.timeStart='';
                this.timeEnd='';
                this.getHome();
            }
        },
        onChange(type) {
            if(type==2){
                 if(this.timeStart2){
                    this.start = moment(this.timeStart2).startOf('day').format("x");
                }else{
                    this.start='';
                }
                if(this.timeEnd2){
                    this.end = moment(this.timeEnd2).endOf('day').format("x");
                }else{
                   this.end='';
                }
                if(this.start && this.end){
                    this.getRank();
                }
            }else{
                if(this.timeStart){
                    this.start = moment(this.timeStart).startOf('day').format("x");
                }else{
                    this.start='';
                }
                if(this.timeEnd){
                    this.end = moment(this.timeEnd).endOf('day').format("x");
                }else{
                   this.end='';
                }
                if(this.start && this.end){
                    this.getHome();
                }
            }
        },
        // 获取排行榜
        getRank() {
            requestXml("/scrm/Statistics/getRanking","POST",(res) => {
                this.rank = res;
            },{ start: this.start, end: this.end });
        },
        // // 获取平台动态
        // getNews() {
        //     requestXml("/index/index/news?scrm", "POST", (res) => {
        //         for (let i = 0; i < res.length; i++) {
        //             res[i].created = getDateTime(res[i].created);
        //         }
        //         this.news = res;
        //     });
        // },
    },
};
</script>
<style scoped>
.home .ant-calendar-picker {
    margin: 0 2rem;
}
.home .ant-input {
    width: 22rem;
    height: 25px;
}
.txt {
    font-size: 26px;
    float: left;
}
.red {
    color: #f01010;
}
.yellow {
    color: #feaa0f;
}
.orange {
    color: #ff6c0c;
}
.blue {
    color: #36a3fe;
}

.header {
    width: 100%;
    padding-bottom: 1.5rem;
    background-color: #fff;
}
.head_date{
	width: 22rem;
}
.title {
    color: #1a1a1a;
    /* font-size: 16px; */
}
.div_top {
    padding: 0 15px;
    justify-content: space-between;
    height: 40px;
    line-height: 40px;
    border-bottom: 1px solid #eeeeee;
}
.div_top .right {
    align-items: center;
	justify-content: space-between;
	width: 100%;
}
.div_top .right .quick {
    display: inline-block;
    margin-right: 10px;
}
.bottom {
    padding: 0 15px;
}
.header .header_bottom {
    color: #202020;
    font-weight: 600;
    justify-content: space-around;
}
.header .header_bottom .client {
    flex-direction: column;
}
.marf {
    margin-left: 4rem;
}
.title_small {
    color: #138fff;
    font-weight: 600;
    height: 8rem;
    line-height: 8rem;
}
.title_small img {
    width: 3.2rem;
    height: 3.2rem;
    margin: 2.4rem 1rem 0 0;
}
.header .header_bottom .client .client_con div {
    width: 33.3%;
}
.header .header_bottom .order {
    /* flex-direction: column;
		align-items: center; */
    width: 50vw;
}
.header .header_bottom .imgs {
    width: 56px;
    height: 56px;
    margin-right: 10px;
}

.div_con {
    margin-top: 2rem;
    padding-bottom: 1.5rem;
    background-color: #fff;
}
/* 任务 */
.task {
    padding: 2rem 0;
}
.task .task_bottom {
    margin: 10px 20px 0;
    padding: 15px;
    border: 1px solid #eeeeee;
    border-radius: 2px;
}
/* .task .task_up {
    justify-content: space-between;
} */
.task .task_title {
    color: #202020;
    font-size: 18px;
}
.taskList{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.task_up{
    width: 60px;
    display: inline-block;
}
.task_down{
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.task_content{
	display: block;
	word-break:break-all;
}
.task .task_down {
    justify-content: space-between;
    color: #999;
}
/* 排行榜 */
/* .ranking .ranking_bottom{
		margin: 20px 20px 0;
	} */
.ranking .ranking_up {
    height: 42px;
    line-height: 42px;
    background: #ebf6ff;
    border-radius: 2px;
    color: #161616;
}
.ranking .ranking_content {
    justify-content: space-between;
    height: 7rem;
    line-height: 7rem;
    /* font-size: 2.8rem; */
    width: 90%;
    margin: 0 auto;
    color: #161616;
    align-items: center;
}
.ranking .ranking_content img {
    width: 4rem;
    height: 4.6rem;
}
.ranking .ranking_content .ranking_num {
  width: 21px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  color: #1f90ff;
  background: #d2e9ff;
  font-size: 16px;
  border-radius: 50%;
}
/* 动态 */
.dynamic .dynamic_content {
    padding: 0 1.5rem;
    height: 6rem;
    line-height: 6rem;
    color: #666;
    justify-content: space-between;
}
.dynamic .dynamic_content:hover {
    color: #27a5ff;
}
.dynamic .dynamic_content .dynamic_title {
    display: flex;
    width: 75%;
}
.dynamic .dynamic_content .dynamic_title .con {
    display: inline-block;
    width: 95%;
    margin-left: 5px;
}
</style>
 